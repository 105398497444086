<template>
  <b-row>
    <b-col cols="6">
      <b-form-datepicker
        v-model="dateValue[0]"
        class="mb-2"
        placeholder="От"
        locale="ru-RU"
        :date-format-options="{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }"
        @input="changeDate"
      />
    </b-col>
    <b-col cols="6">
      <b-form-datepicker
        v-model="dateValue[1]"
        class="mb-2"
        placeholder="До"
        locale="ru-RU"
        :date-format-options="{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }"
        @input="changeDate"
      />
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "DateFromTo",
  props: {
    date: [Array, String],
  },
  model: {
    prop: "date",
    event: "change",
  },
  data() {
    return {
      dateValue: this.date ?? ["", ""],
    };
  },
  methods: {
    changeDate() {
      this.$emit("change", this.dateValue);
    },
  },
};
</script>

<style
		lang="sass"
		scoped
>
</style>
