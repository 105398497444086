<template>
    <div class="d-flex align-items-center reset-dropdown-styles">

        <b-dropdown
            v-if="columns"
            size="lg"
            variant="link"
            toggle-class="text-decoration-none"
            class="filter-table-head"
            no-caret>
            <template #button-content>
                <BButton
                    class="mr-3"
                    variant="secondary">
                    <BIcon
                        style="transform: rotate(-90deg)"
                        icon="sliders"/>
                </BButton>
            </template>
            <div class="p-2">
                <div class="h6">Столбцы</div>
                <b-form-checkbox-group
                    v-model="addedColumns"
                    stacked
                    @change="changeColumns">

                    <draggable
                        :list="sortableColumns"
                        class="list-group"
                        ghost-class="ghost"
                        @start="dragging = true"
                        @end="dragging = false"
                    >
                        <template v-for="column in sortableColumns">
                            <b-form-checkbox
                                v-if="column.key !== 'select'"
                                :key="column.key"
                                :value="column.label">
                                {{ column.full }}
                            </b-form-checkbox>
                        </template>

                    </draggable>

                </b-form-checkbox-group>
            </div>
        </b-dropdown>

        <BButton
            variant="primary"
            class="px-4 mr-2"
            @click="$emit('open-filter-modal')">
            Фильтр
        </BButton>

        <BButton
            variant="outline-danger"
            class="px-4 mr-2"
            @click="[ $emit('clear'), filter = '' ]">Сброс {{ filter }}
        </BButton>

        <BInputGroup style="max-width: 41.67rem">
            <BFormInput
                type="text"
                class="border-right-0"
                placeholder="Поиск"
                v-model="filter"
                @change="search"
                @input="search"/>
            <BInputGroupAppend>
                <BInputGroupText
                    class="bg-transparent border-left-0">
                    <BIcon
                        icon="search"
                        class="text-primary"/>
                </BInputGroupText>
            </BInputGroupAppend>
        </BInputGroup>

    </div>
</template>

<script>

  import draggable from 'vuedraggable'

    export default {
        name: "TableFilter",
        props: {
            columns: Array,
            uniqueKey: String
        },
        components: {
            draggable
        },
        data: () => ({
            addedColumns: [],
            filter: '',
            dragging: false,
            sortableColumns: [],
        }),
        watch: {
            columns () {
                this.compareColumns()
                this.setColumnsToLC()
            },
            sortableColumns (newVal) {
                this.$emit('columns-change-position', newVal)
            }
        },
        methods: {
            compareColumns () {
                this.addedColumns = []
                this.columns.forEach(item => {
                    if (item.openDefault) {
                        this.addedColumns.push(item.label)
                    }
                })
            },
            setColumnsToLC () {
                // this.sortableColumns = this.columns
                // localStorage.setItem(this.uniqueKey, JSON.stringify(this.sortableColumns))
            },
            changeColumns () {
                this.$emit('update-selected-columns', this.addedColumns)
            },
            search () {
                this.$emit('search', this.filter)
            }
        },
        mounted() {
            // const columnsFromLC = localStorage.getItem(this.uniqueKey)
            // if (columnsFromLC) {
                // const parsedColumnsArray = JSON.parse(localStorage.getItem(this.uniqueKey))
                // this.sortableColumns = this.columns
            // } else {
                this.sortableColumns = this.columns
            // }
            this.compareColumns()
        }
    }
</script>

<style scoped>

</style>
