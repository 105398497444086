var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"placeholder":"От","locale":"ru-RU","date-format-options":{
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }},on:{"input":_vm.changeDate},model:{value:(_vm.dateValue[0]),callback:function ($$v) {_vm.$set(_vm.dateValue, 0, $$v)},expression:"dateValue[0]"}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"placeholder":"До","locale":"ru-RU","date-format-options":{
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }},on:{"input":_vm.changeDate},model:{value:(_vm.dateValue[1]),callback:function ($$v) {_vm.$set(_vm.dateValue, 1, $$v)},expression:"dateValue[1]"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }